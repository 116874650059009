
.section-modHandling {
    background-color: var(--section-secondary-background-color); /* Heller Hintergrund */
    /*background-color: var(--section-secondary-background-color);*/
    /*background-image: url('../images/mod-handling.jpg');*/
    background-size: cover; /* Decke den gesamten Bereich ab */
    background-position: center; /* Zentriere das Bild */
    padding: 40px; /* Innenabstand */
    border-radius: 8px; /* Ecken abrunden */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Leichter Schatten */
}


ul {
    list-style-type: none; /* Aufzählungszeichen entfernen */
    padding-left: 0; /* Padding entfernen */
    text-align: center; /* Text zentrieren */
}

li {
    margin-bottom: 10px; /* Abstand zwischen den Listenelementen */
    display: flex; /* Flexbox für Icons und Text */
    align-items: center; /* Vertikale Ausrichtung */
    justify-content: center; /* Horizontale Ausrichtung */
}

li svg {
    margin-right: 10px; /* Abstand zwischen Icon und Text */
}

.img-fluid {
    max-width: 100%; /* Bild responsiv machen */
    height: auto; /* Höhe automatisch anpassen */
    border-radius: 8px; /* Optional: Runde Ecken für das Bild */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional: Schatten hinzufügen */
}
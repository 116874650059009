/* colors.css */
:root {
    --primary-color: #7ead98;
    --primary-darker-color: #4B6B5B;
    --secondary-color: #e6eae6;
    --accent-color: #FFD700;
    --hover-accent-color: #FFA500;
    --neutral-color: #FFFFFF;
    --section-primary-background-color: #7ead98;
    --section-primary-background-color-rgb: 126, 173, 152;
    --section-secondary-background-color: #e1e4e1;
    --section-secondary-background-color-rgb: 225, 228, 225;
}

.navbar-wrapper {
    height: 56px;
}

.navbar-brand-small {
    display: flex;
    position: absolute;
    height: 40px;
    margin-left: 20px;
}

.nav-bar-logo-small {
    height: 40px;
    margin-right: 10px;
}

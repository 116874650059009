.section-intro {
    background-color: var(--section-primary-background-color);
}

.logo-brand-container {
    width: 100%;
    left: 50%;
    right: 50%;
}

.logo-hero {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20vw;
    height: 20vw;
}

.logo-brand-name {
    color: var(--neutral-color);
    text-align: center;
    font-weight: bold;
    font-size: 5.1vw;
}

.heading-label {
    margin-top: 150px;
    font-family: 'Amatic SC',sans-serif;
    color: var(--primary-color);
    font-size: 5vw;
    font-weight: 400;
    text-align: center;
}

.section-heading {
    min-height: 70vh;
    background-image: url('../images/hero-img.png');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: center;
}

.section-heading-intro {
    background-color: rgba(var(--section-primary-background-color-rgb), 0.9);
    padding: 20px;
    color: var(--secondary-color);
}
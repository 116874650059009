.button-custom {
    background-color: var(--primary-darker-color) !important;
    color: var(--neutral-color) !important;
    border-color: var(--primary-darker-color) !important;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease !important;
    font-size: 16px !important;
    font-weight: bold !important;
}

.button-custom:hover {
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
    color: var(--primary-darker-color) !important;
}

.floating-button {
    position: fixed !important;
    bottom: 65px !important;
    right: 20px !important;
    background-color: var(--primary-darker-color) !important;
    color: var(--neutral-color) !important;
    border-color: var(--primary-darker-color) !important;
    border-radius: 30px !important;
    padding: 10px 20px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    z-index: 1000 !important;
    cursor: pointer !important;
}

.floating-button:hover {
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
    color: var(--primary-darker-color) !important;
}

.floating-button:active {
    background-color: var(--primary-darker-color) !important;
    border-color: var(--primary-darker-color) !important;
    color: var(--neutral-color) !important;
}

/* ScrollButtons.css */
.scroll-button-container {
    position: fixed;
    right: 20px;
    top: 100px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.scroll-button-container button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.scroll-button-container button:hover {
    background-color: #0056b3;
}
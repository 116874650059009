.container-breastFeeding {
    background-color: rgba(var(--section-primary-background-color-rgb), 0.9);
}

.section-breastFeeding {
    background-image: url('../images/breastfeeding-support.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    justify-content: center;
}
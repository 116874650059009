.section-timeline {
    background-color: var(--section-secondary-background-color);
}

.timeline-container {
    height: 100%;
    padding: 0 0 0 10px;
    border-left: 3px dotted var(--primary-color); /* Gepunkteter linker Rand */
    border-bottom: 3px solid var(--primary-color); /* Durchgezogener unterer Rand */
    position: relative;
}

/* Punkt unten links */
.timeline-container::before {
    content: '';
    width: 15px;
    height: 15px;
    background-color: var(--primary-color); /* Farbe des Punktes */
    border-radius: 50%;
    position: absolute;
    bottom: -10px; /* Positioniert den Punkt am unteren Rand */
    left: -10px; /* Positioniert den Punkt am linken Rand */
}

/* Pfeilspitze unten rechts */
.timeline-item::after {
    content: '\2192'; /* Unicode für den Pfeil nach rechts */
    font-size: 24px;
    color: var(--primary-color);
    position: absolute;
    bottom: -19px;
    right: -5px;
}

.timeline-icon {
    width: 60px;
    height: 60px;
    background: var(--primary-color);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3vw;
    margin-right: 20px;
}
.margin-top-50 {
    margin-top: 50px !important;
}

.margin-top {
    margin-top: 20px !important;
}

.margin-bottom {
    margin-bottom: 20px !important;
}

.padding-top {
    padding-top: 20px !important;
}

.padding-top-50 {
    padding-top: 50px !important;
}

.padding-bottom {
    padding-bottom: 20px !important;
}

.text {
    font-family: Verdana,Geneva, sans-serif
}

.text-font-size-16 {
    font-size: 16px;
}

.text-font-size-24 {
    font-size: 24px;
}

.text-font-size-48 {
    font-size: 48px;
}

.text-to-bottom-container {
    position: relative;
    width: 100%;
}

.text-to-bottom {
    position: absolute;
    bottom: 50%;
    left: 50%;
    right: 50%;
}

.text-color-white {
    color: white;
}

.text-align-center {
    text-align: center;
}

.section {
    padding: 20px 60px 20px 60px;
}

.section-header-title {
    color: var(--primary-color);
    text-align: center;
    font-weight: bold;
    font-size: 4vw;
}

.section-header-secondary-title {
    color: var(--secondary-color);
    text-align: center;
    font-weight: bold;
    font-size: 4vw;
}

.page-standalone {
    min-height: 86vh;
}
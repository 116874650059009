.section-breastFeedingWorkshop {
    background-color: var(--section-primary-background-color);
}
.breastfeeding-image {
    max-width: 100%; /* Bild responsiv machen */
    height: auto; /* Höhe automatisch anpassen */
    border-radius: 8px; /* Optional: Runde Ecken für das Bild */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional: Schatten hinzufügen */
}

.breastfeeding-list {
    list-style: none; /* Remove default list styles */
    padding: 0; /* Remove padding */
}

.breastfeeding-list li {
    display: flex; /* Use flexbox for icons and text */
    align-items: center; /* Center align icons and text */
    margin: 10px 0; /* Space between list items */
}

.breastfeeding-list li svg {
    margin-right: 10px; /* Space between icon and text */
}

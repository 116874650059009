.container-babyFood {
    background-color: rgba(var(--section-secondary-background-color-rgb), 0.9);
}

.section-babyFood {
    background-image: url('../images/supportFood.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    justify-content: center;
}


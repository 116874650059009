.section-contact {
    background-color: var(--section-secondary-background-color);
}

.contact-card {
    margin: 15px;
}

.card {
    background-color: var(--neutral-color);
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    height: 100%; /* Sicherstellen, dass alle Karten die gleiche Höhe haben */
}

.card-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--section-secondary-background-color);
    background-color: var(--secondary-color);
}

.icon {
    font-size: 2.5vw;
    margin-right: 10px;
    color: var(--primary-color); /* Primärfarbe für die Icons */
}

.card-title {
    margin: 0;
    color: var(--primary-darker-color);
    font-size: 2.5vw;
}

.card-body {
    padding: 20px;
    flex-grow: 1; /* Flexibles Wachstum, um Platz zu füllen */
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

a {
    color: var(--primary-darker-color) !important;
    text-decoration: none !important;
    transition: color 0.3s !important;
}

a:hover {
    color: var(--hover-accent-color) !important;
    text-decoration: underline !important;
}
